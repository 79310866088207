import { FC, useRef } from 'react';
import styles from './SearchBar.module.scss';
import SearchIcon from 'assets/search.svg';
import CloseIcon from 'assets/close.svg';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  searchQuery: string;
  setQuery(newQuery: string): void;
  inputId: string;
  name?: string;
  ref?: React.RefObject<HTMLInputElement | null>;
};

export const SearchBar: FC<Props> = ({
  searchQuery,
  setQuery,
  inputId,
  name,
  ref,
}) => {
  const t = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.inputContainer}>
      <SearchIcon className={styles.inputIcon} />
      <VisuallyHidden asChild>
        <label htmlFor={inputId}>{t('search')}</label>
      </VisuallyHidden>
      <input
        value={searchQuery}
        type="search"
        id={inputId}
        className={styles.searchBar}
        name={name}
        placeholder={t('search')}
        autoComplete="off"
        onChange={(e) => {
          setQuery(e.currentTarget.value);
        }}
        ref={(el) => {
          inputRef.current = el;
          if (ref) ref.current = el;
        }}
      />
      {searchQuery ? (
        <button
          className={styles.clearButton}
          onClick={() => {
            setQuery('');
            inputRef.current?.focus();
          }}
          type="button"
        >
          <CloseIcon className={styles.clearIcon} />
          <VisuallyHidden>{t('clearInput')}</VisuallyHidden>
        </button>
      ) : null}
    </div>
  );
};
