import { apmRum } from 'services/apm-rum';
import logger from 'services/logger';
import { SWRConfiguration } from 'swr';

/**
 * Returns fetch function that aborts the request after specified timeout
 * @param timeout milliseconds to wait before aborting the fetch
 * @returns fetch function with abort signal
 */
export function fetchWithTimeout(timeout: number): typeof fetch {
  if (
    typeof AbortSignal != 'undefined' &&
    typeof AbortSignal.timeout == 'function'
  ) {
    return function timeoutFetch(input, init) {
      return fetch(input, {
        ...init,
        signal: AbortSignal.timeout(timeout),
      });
    };
  }

  if (typeof AbortController != 'undefined') {
    return async function timeoutFetch(input, init) {
      const controller = new AbortController();

      const timeoutId = setTimeout(() => {
        controller.abort(
          new DOMException(
            'The operation was aborted due to timeout',
            'TimeoutError'
          )
        );
      }, timeout);

      const response = await fetch(input, {
        ...init,
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      return response;
    };
  }

  logger.warn('AbortController not available, fetch timeout not supported');
  return fetch;
}

export const globalSWRConfig = {
  onError(err: unknown) {
    logger.error(err);
    if (
      typeof err === 'string' ||
      err instanceof Error ||
      err instanceof DOMException
    )
      apmRum?.captureError(err);
  },
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
} as const satisfies SWRConfiguration;
