import { brazeSdkProperties, cspReportUri } from 'services/properties/frontend';

export const getCspDirectives = () =>
  [
    "default-src 'self' *.yle.fi *.ylestatic.fi ping.chartbeat.net",
    "script-src 'self' 'unsafe-eval' 'unsafe-inline' *.yle.fi *.ylestatic.fi *.userreport.com *.kaltura.com www.gstatic.com https://js.appboycdn.com",
    `connect-src 'self' *.yle.fi wss://*.yle.fi *.ylestatic.fi *.demdex.net endpoint.finnpanel.fi *.kaltura.com *.akamaized.net *.litix.io ${brazeSdkProperties.endpoint} https://fi-yle-dev1.mini.snplow.net`,
    "style-src 'self' 'unsafe-inline' *.yle.fi *.ylestatic.fi *.googleapis.com",
    "font-src 'self' data: *.yle.fi *.ylestatic.fi *.googleapis.com *.gstatic.com appboy-images.com braze-images.com cdn.braze.eu",
    "img-src 'self' data: yle.fi *.yle.fi *.ylestatic.fi yleisradio.d3.sc.omtrdc.net ping.chartbeat.net *.userreport.com *.kaltura.com appboy-images.com braze-images.com cdn.braze.eu",
    "media-src 'self' data: blob: *.akamaized.net *.kaltura.com *.akamaihd.net",
    "frame-src 'self' *.userreport.com *.yle.fi",
    'worker-src blob:',
    "object-src 'none'",
    `report-uri ${cspReportUri}`,
    `report-to ${cspReportUri}`,
  ].join('; ');
