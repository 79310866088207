import getConfig from 'next/config';
import { YleAnalyticsAccount } from 'types/yleAnalytics';
import { isTestEnvironment } from 'utils/environment';

const {
  areenaApiUrlOverride,
  appIdFrontend: appId = '',
  appKeyFrontend: appKey = '',
  brazeSdkApiKey,
} = getConfig().publicRuntimeConfig;

export const areenaApiProperties: {
  appBase: string;
  appId: string;
  appKey: string;
} = {
  appBase:
    areenaApiUrlOverride ||
    (isTestEnvironment()
      ? 'https://areena.api-test.yle.fi'
      : 'https://areena.api.yle.fi'),
  appId,
  appKey,
};

// From https://github.com/braze-inc/braze-web-sdk#libraries
// Keep version in sync with the "@braze/web-sdk" npm package version
export const brazeSdkProperties = {
  url: 'https://js.appboycdn.com/web-sdk/5.7/braze.min.js',
  apiKey: brazeSdkApiKey,
  endpoint: 'sdk.fra-02.braze.eu',
} as const;

export const locationsApiProperties: {
  urlBase: string;
  appId: string;
  appKey: string;
} = {
  urlBase: isTestEnvironment()
    ? 'https://locations.api-test.yle.fi'
    : 'https://locations.api.yle.fi',
  appId,
  appKey,
};

// FIAM Yle Areena site mediaId and Areena Video sectionId
export const audienceProject = {
  sectionId: 'ea81e5ea-1be8-4f79-8e07-533c36916721',
  mediaId: '780f7b0a-a092-4ec8-b9aa-e3b734ba573b',
};

export const yleConsentSdkUrl = isTestEnvironment()
  ? 'https://yle-consent-sdk.test.yle.fi/v4/yle-consent-sdk.latest.js'
  : 'https://yle-consent-sdk.yle.fi/v4/yle-consent-sdk.latest.js';

export const yleTunnusSdkUrl = isTestEnvironment()
  ? 'https://tunnus-sdk.test.yle.fi/v1/index.js'
  : 'https://tunnus-sdk.yle.fi/v1/index.js';

export const yleAnalyticsSdkUrl = isTestEnvironment()
  ? 'https://analytics-sdk-test.yle.fi/yle-analytics.min.js'
  : 'https://analytics-sdk.yle.fi/yle-analytics.min.js';

export const yleAnalyticsAccounts = (
  hostname: string
): YleAnalyticsAccount[] => [
  { type: 'adobe', id: 'areena' },
  {
    type: 'audienceproject',
    id: { section: audienceProject.sectionId, media: audienceProject.mediaId },
  },
  { type: 'chartbeat', id: hostname },
  { type: 'datacloud', id: 'areena' },
];

export const snowplowAnalyticsProperties = {
  appId: isTestEnvironment() ? 'areena_web-test' : 'areena_web-prod',
  collectorUrl: isTestEnvironment()
    ? 'haavi-test.yle.fi'
    : 'fi-yle-dev1.mini.snplow.net', // TODO, change prod url to haavi.yle.fi when ready for prod
  miniCollectorUrl: 'fi-yle-dev1.mini.snplow.net',
  trackerNamespace: 'sp1',
};

export const ylePlayerScriptUrl = isTestEnvironment()
  ? 'https://player-v2.test.yle.fi/embed.js'
  : 'https://player-v2.yle.fi/embed.js';

export const ylePlayerStagingScriptUrl =
  'https://player-v2.test.yle.fi/staging/embed.js';

export const chatScriptUrl = isTestEnvironment()
  ? 'https://yle-comments-livefeed-widget.apps-test.yle.fi/js/yle-livefeed.js'
  : 'https://yle-comments-livefeed-widget.apps.yle.fi/js/yle-livefeed.js';

export const chatStyleUrl = isTestEnvironment()
  ? 'https://yle-comments-livefeed-widget.apps-test.yle.fi/css/yle-livefeed.css'
  : 'https://yle-comments-livefeed-widget.apps.yle.fi/css/yle-livefeed.css';

export const cspReportUri = isTestEnvironment()
  ? 'https://csp-test.aws.yle.fi/index'
  : 'https://csp.aws.yle.fi/index';

export const yleFontPreloadUrls = [
  'https://design-system.cdn.yle.fi/fonts/yle-next/1.0.0/YleNextVar.woff2',
  'https://design-system.cdn.yle.fi/fonts/yle-next/1.0.0/YleNextVar-Italic.woff2',
];
