import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { Language, List, Service } from '@yleisradio/areena-types';
import {
  BrowseMenuContent,
  BrowseMenuContentBlock,
} from '@yleisradio/areena-types/domain/v2/browseMenuContent';
import CloseIcon from 'assets/close.svg';
import classNames from 'classnames';
import { CategoryLinkControl } from 'components/CategoryLinkControl/CategoryLinkControl';
import { ImageLinkControl } from 'components/ImageLinkControl/ImageLinkControl';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { ServiceSwitch } from 'components/ServiceSwitch';
import { TextLinkControl } from 'components/TextLinkControl/TextLinkControl';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useCustomEventAnalytics } from 'hooks/analytics';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import Form from 'next/form';
import { FC, useEffect, useRef, useState } from 'react';
import { createSearchResultsPointer } from 'services/areena-api/pointer';
import { getControlKey } from 'utils/control';
import { serviceRoutes } from 'utils/routes';
import {
  activeServiceQueryParameterName,
  searchQueryParameterName,
} from 'utils/search-params';
import { AreenaLink } from '../AreenaLink';
import styles from './MegaMenu.module.scss';
import { MegaMenuSearchList } from './MegaMenuSearchList/MegaMenuSearchList';

type Props = {
  searchQuery: string;
  closeMenu(): void;
  setSearchQuery(query: string): void;
  browseMenuContent: BrowseMenuContent | null;
};

const findBlock =
  (blockType: BrowseMenuContentBlock['type']) =>
  (block: BrowseMenuContentBlock) =>
    block.type === blockType;

export const MegaMenu: FC<Props> = ({
  searchQuery,
  closeMenu,
  setSearchQuery,
  browseMenuContent,
}) => {
  const { areenaService } = useAreenaService();
  const [activeService, setActiveService] = useState<Service>(
    areenaService ?? 'tv'
  );
  const language = useUILanguage();
  const t = useTranslation();
  const trackEvent = useCustomEventAnalytics();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  useEffect(() => {
    if (!searchQuery) {
      setDebouncedSearchQuery(searchQuery);
      return;
    }

    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery]);

  const isViewportLarge = useBreakpoint('tablet-landscape');

  if (!browseMenuContent) return null;

  const isPodcastService = activeService === 'radio';

  const activeBlocks = isPodcastService
    ? browseMenuContent['podcastBlocks']
    : browseMenuContent['tvBlocks'];
  const categoryImageLinkBlock = activeBlocks.find(
    findBlock('categoryImageLinks')
  );
  const categoryLinkBlock = activeBlocks.find(findBlock('categoryLinks'));
  const textLinkBlock = activeBlocks.find(findBlock('textLinks'));

  const imageLinkSizes = isPodcastService
    ? '(min-width: 1400px) 12rem, (min-width: 1020px) 10rem, (min-width: 640px) 8rem, (min-width: 480px) 30vw, 45vw'
    : '(min-width: 1400px) 16rem, (min-width: 1020px) 14rem, (min-width: 640px) 12rem, (min-width: 480px) 30vw, 45vw';

  const searchElement = (
    <Form
      action={`/${serviceRoutes.search[language]}`}
      className={styles.searchBarContainer}
    >
      <SearchBar
        searchQuery={searchQuery}
        setQuery={setSearchQuery}
        inputId="navigation-search-input"
        name={searchQueryParameterName}
        ref={searchInputRef}
      />
      <input
        type="hidden"
        name={activeServiceQueryParameterName}
        value={activeService}
      />
      <input type="submit" hidden />
    </Form>
  );

  return (
    <Dialog.Content
      className={styles.root}
      onOpenAutoFocus={(e) => {
        if (searchInputRef.current) {
          e.preventDefault();
          searchInputRef.current.focus();
        }
      }}
    >
      <VisuallyHidden asChild>
        <Dialog.Title>{t('searchAndBrowse')}</Dialog.Title>
      </VisuallyHidden>

      <div className={styles.upperPart}>
        <div className={styles.logoContainer}>
          <AreenaLink
            activeNavigationService={areenaService}
            onClick={closeMenu}
          />
        </div>

        {isViewportLarge && searchElement}

        <div className={styles.closeButtonContainer}>
          <Dialog.Close aria-label={t('close')} className={styles.closeButton}>
            <span className={styles.closeButtonText}>{t('close')}</span>
            <CloseIcon className={styles.closeButtonIcon} />
          </Dialog.Close>
        </div>
      </div>

      <div className={styles.lowerPart}>
        {!isViewportLarge && searchElement}

        <div className={styles.serviceSwitchContainer}>
          <ServiceSwitch
            activeService={activeService}
            setActiveService={setActiveService}
            onClick={() => trackEvent('header-service-switch-click')}
          />
        </div>

        {!debouncedSearchQuery && (
          <VisuallyHidden asChild>
            <h3>{t('categories')}</h3>
          </VisuallyHidden>
        )}

        {debouncedSearchQuery ? (
          <MegaMenuSearchList
            lists={{
              tv: createSearchResultsList(debouncedSearchQuery, language, 'tv'),
              radio: createSearchResultsList(
                debouncedSearchQuery,
                language,
                'radio'
              ),
            }}
            searchQuery={debouncedSearchQuery}
            clearSearchQuery={() => setSearchQuery('')}
            service={activeService}
            closeMenu={closeMenu}
          />
        ) : null}

        {categoryImageLinkBlock &&
          categoryImageLinkBlock.controls &&
          categoryImageLinkBlock.controls.length > 0 &&
          !debouncedSearchQuery && (
            <div
              className={classNames(
                styles.imageLinkContainer,
                isPodcastService && styles.imageLinkContainerPodcast
              )}
            >
              {categoryImageLinkBlock.controls.map((control) => (
                <ImageLinkControl
                  control={control}
                  key={getControlKey(control)}
                  onClick={closeMenu}
                  service={activeService}
                  sizes={imageLinkSizes}
                />
              ))}
            </div>
          )}

        {categoryLinkBlock &&
          categoryLinkBlock.controls &&
          categoryLinkBlock.controls.length > 0 &&
          !debouncedSearchQuery && (
            <div className={styles.categoryLinkContainer}>
              {categoryLinkBlock.controls.map((control) => (
                <CategoryLinkControl
                  key={getControlKey(control)}
                  control={control}
                  onClick={closeMenu}
                  service={activeService}
                />
              ))}
            </div>
          )}

        {textLinkBlock &&
          textLinkBlock.controls &&
          textLinkBlock.controls.length > 0 &&
          !debouncedSearchQuery && (
            <div className={styles.textLinkContainer}>
              {textLinkBlock.controls.map((control) => (
                <TextLinkControl
                  key={getControlKey(control)}
                  control={control}
                  onClick={closeMenu}
                  service={activeService}
                />
              ))}
            </div>
          )}
      </div>
    </Dialog.Content>
  );
};

function createSearchResultsList(
  query: string,
  language: Language,
  service: Service
): List {
  return {
    controls: [],
    filters: [],
    presentation: 'medium-grid',
    source: createSearchResultsPointer(query, language, service),
    style: {
      image: service === 'radio' ? '1:1' : '16:9',
      layout: 'horizontal',
      size: 'small',
    },
    type: 'list',
  };
}
