import { useLocationContext } from 'contexts/LocationContext';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useMemo } from 'react';

/**
 * Location parameters to be added to areena-api requests
 * @see {@link https://docs.google.com/document/d/1vWk4Y2YhvB8z_FJRX9qDtJuAAfIFdJsjWlDdrrhP3DA/edit#heading=h.86jqya4zjlc0 | Areena UI API documentation }
 * @see https://github.com/Yleisradio/yle-aws-areena/blob/master/terraform/areena-api-foundation/files/region/handler.js
 */
type LocationParameters = {
  /** an ISO 3166-1 alpha-2 country code eg. as provided by Locations API */
  country?: string;
  /** true when user is currently located within the portability region (provided by Locations API) */
  isPortabilityRegion?: 'true';
  /** true when user’s municipality of residence is in Finland (provided by Login API) */
  residenceInFinland?: 'true';
};

/**
 * Get geolocation parameters to be added to areena-api requests
 * @returns location parameters or undefined if dependencies are not ready
 */
export function useLocationParameters(): LocationParameters | undefined {
  const location = useLocationContext();

  const { isUserInformationFetched, userInformation } = useTunnusContext();
  const hasUserResidenceInFinland = userInformation?.residenceInFinland;

  return useMemo<LocationParameters | undefined>(() => {
    if (!location || !isUserInformationFetched) return undefined;

    const parameters: LocationParameters = {};

    if (location.country_code) parameters.country = location.country_code;
    if (location.is_portability_region) parameters.isPortabilityRegion = 'true';
    if (hasUserResidenceInFinland) parameters.residenceInFinland = 'true';

    return parameters;
  }, [hasUserResidenceInFinland, isUserInformationFetched, location]);
}
