import { useTunnusContext } from 'contexts/TunnusContext';
import { useUILanguage } from 'hooks/useUILanguage';
import Script from 'next/script';
import type {
  ErrorResponseData,
  SuccessResponseData,
} from 'pages/api/braze-token';
import React, { useContext, useEffect, useState } from 'react';
import { apmRum } from 'services/apm-rum';
import { init, teardown } from 'services/braze';
import logger from 'services/logger';
import { brazeSdkProperties } from 'services/properties/frontend';
import useSWR from 'swr';
import type { BrazeSdkType } from './types';

const BrazeSdkContext = React.createContext<BrazeSdkType | null>(null);

type BrazeSdkProviderProps = {
  children: React.ReactNode;
};

// Global braze is only used for the SDK initialization, otherwise it's used through the context
declare const braze: BrazeSdkType | undefined;

async function fetcher(url: string): Promise<string> {
  const res = await fetch(url);
  if (res.ok) {
    const json = (await res.json()) as SuccessResponseData;
    return json.token;
  } else {
    const json = (await res.json()) as ErrorResponseData;
    throw new Error(json.message || `Non-OK response status ${res.status}`);
  }
}

export const BrazeSdkProvider: React.FC<BrazeSdkProviderProps> = ({
  children,
}) => {
  const language = useUILanguage();
  const { userInformation: { userId } = {} } = useTunnusContext();

  const [brazeInstance, setBrazeInstance] = useState<BrazeSdkType | null>(null);

  const isBrazeAllowed = !!userId;

  const { data: token, mutate: refreshToken } = useSWR(
    isBrazeAllowed ? '/api/braze-token' : null,
    fetcher
  );

  // Teardown SDK when Braze is no longer allowed
  useEffect(() => {
    if (!isBrazeAllowed && brazeInstance) {
      try {
        teardown(brazeInstance);
        setBrazeInstance(null);
      } catch (error) {
        logger.error(error, 'Failed to teardown Braze SDK');
        if (error instanceof Error) apmRum?.captureError(error);
      }
    }
  }, [brazeInstance, isBrazeAllowed]);

  // Pass changed token to the SDK
  useEffect(() => {
    if (!isBrazeAllowed) {
      return;
    }

    if (brazeInstance && token) {
      brazeInstance.setSdkAuthenticationSignature(token);
    }
  }, [brazeInstance, isBrazeAllowed, token]);

  return (
    <BrazeSdkContext.Provider value={brazeInstance}>
      {isBrazeAllowed && token ? (
        <Script
          src={brazeSdkProperties.url}
          strategy="lazyOnload"
          onLoad={() => {
            (async () => {
              try {
                if (typeof braze === 'undefined') {
                  throw new Error('Braze SDK global not defined');
                }
                await init(braze, userId, token, refreshToken, language);
                setBrazeInstance(braze);
              } catch (error) {
                logger.error(error, 'Failed to initialize Braze SDK');
                if (error instanceof Error) apmRum?.captureError(error);
              }
            })();
          }}
        />
      ) : null}

      {children}
    </BrazeSdkContext.Provider>
  );
};

export function useBrazeSdk(): BrazeSdkType | null {
  return useContext(BrazeSdkContext);
}
