import { Control, Pointer } from '@yleisradio/areena-types';
import { useState } from 'react';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { getAvailablePlayerByPointer } from 'services/areena-api/fetchers';
import logger from 'services/logger';
import { Action } from './type';
import { toastPlaybackError } from 'components/Notifications';
import { useUILanguage } from 'hooks/useUILanguage';
import { useLocationParameters } from 'hooks/useLocationParameters';

type PlayerActionForControlProps = {
  control: Control | null;
};

export function usePlayerActionForControl({
  control,
}: PlayerActionForControlProps): Action {
  const pointer = control?.destination;

  return usePlayerActionForPointer({ pointer });
}

type PlayerActionForPointerProps = {
  pointer: Pointer | undefined;
};

export function usePlayerActionForPointer({
  pointer,
}: PlayerActionForPointerProps): Action {
  const { closePlayer, setIsPlaying, startNewSession } = usePlayerState();
  const [isExecuting, setIsExecuting] = useState(false);
  const language = useUILanguage();
  const locationParameters = useLocationParameters();

  async function playerAction() {
    if (isExecuting) {
      return;
    }

    if (!pointer) {
      logger.error(new Error('Pointer needs to be defined'));
      toastPlaybackError(language);
      return;
    }

    if (!locationParameters) {
      logger.error(new Error('Location parameters need to be defined'));
      toastPlaybackError(language);
      return;
    }

    setIsExecuting(true);

    try {
      const player = await getAvailablePlayerByPointer(
        pointer,
        locationParameters
      );

      if (player) {
        startNewSession(player);
        setIsPlaying(true);
      } else {
        logger.debug('No available player, closing player');
        closePlayer();
        toastPlaybackError(language);
      }
    } catch (error) {
      logger.error(error, 'Failed to get player');
      closePlayer();
      toastPlaybackError(language);
    }

    setIsExecuting(false);
  }
  return {
    runAction: playerAction,
    executing: false,
  };
}
