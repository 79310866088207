import dynamic from 'next/dynamic';
import type { Toast } from 'react-hot-toast';
import logger from 'services/logger';
import { Notification, NotificationStyle } from './Notification';

export const NotificationToaster = dynamic(
  () =>
    import('react-hot-toast')
      .then(({ Toaster }) => {
        const LoadedToaster = () => <Toaster position="bottom-right" />;
        return LoadedToaster;
      })
      .catch((e) => {
        logger.error(e, 'react-hot-toast failed to load');
        const NullToaster = () => null;
        return NullToaster;
      }),
  {
    ssr: false,
  }
);

export const toast = async (
  notification: string,
  notificationStyle: NotificationStyle = 'primary',
  helpText?: string
) => {
  import('react-hot-toast')
    .then(({ toast }) =>
      toast.custom(
        (t: Toast) => (
          <Notification
            notification={{ uiMessage: notification }}
            notificationStyle={notificationStyle}
            onClose={() => toast.remove(t.id)}
            helpText={helpText}
            isToast
          />
        ),
        {
          duration: 10000,
        }
      )
    )
    .catch((e) => {
      logger.error(e, 'react-hot-toast failed to load');
      const notificationMessage = [notification, helpText]
        .filter((msg) => !!msg)
        .join(', ');

      const log = notificationStyle === 'error' ? logger.error : logger.info;

      if (notificationMessage.length > 0) {
        log(notificationMessage);
      }
    });
};
