import {
  newTracker,
  addGlobalContexts,
  setUserId,
  clearUserData,
  newSession,
  disableAnonymousTracking,
} from '@snowplow/browser-tracker';
import { snowplowAnalyticsProperties } from '../properties/frontend';
import logger from '../logger';
import { isTestEnvironment } from 'utils/environment';
import { Language } from '@yleisradio/areena-types';
import { Consent } from 'types/yleConsentSdk';
import { yleConsentGenerator, yleContextGenerator } from './contextGenerators';

const isClient = () => typeof window !== 'undefined';

const initSnowplowTracker = (language: Language) => {
  if (!isClient()) {
    return undefined;
  }

  if (!isTestEnvironment()) {
    logger.debug('Snowplow tracker is not activated in prod environment');
    return undefined;
  }

  const { appId, collectorUrl, trackerNamespace } = snowplowAnalyticsProperties;

  const tracker = newTracker(trackerNamespace, collectorUrl, {
    appId: appId,
    discoverRootDomain: true,
    cookieSameSite: 'Lax',
    contexts: {
      browser: true,
    },
    platform: 'web',
    anonymousTracking: { withSessionTracking: true }, // init with anonymous tracking
  });
  logger.debug('Snowplow tracker activated');
  addGlobalContexts([yleContextGenerator(language)]);
  return tracker;
};

const addConsentContext = (consent: Consent) => {
  const contexts = [yleConsentGenerator(consent)];
  disableAnonymousTracking(); // disable anonymous tracking when any consent is given
  addGlobalContexts(contexts);
};

const clearUser = () => {
  clearUserData();
  newSession();
};

const setUser = (userId: string) => {
  disableAnonymousTracking(); // disable anonymous tracking when any user is authenticated
  setUserId(userId);
};

export { initSnowplowTracker, addConsentContext, setUser, clearUser };
