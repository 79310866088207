import { ObservableLabels, ImpressionEvent } from './types';
import { useUILanguage } from 'hooks/useUILanguage';
import { trackEvents } from 'services/yleAnalyticsSdk';
import { useYleSk } from './useYleSk';
import { useCookies } from './useCookies';
import { useCallback } from 'react';

type Props = {
  uiElementPosition: number;
  uiElementType: string;
  platform: 'desktop' | 'mobile';
};

export const useClickCapture = ({
  uiElementPosition,
  uiElementType,
  platform,
}: Props) => {
  const impressionCookiesRef = useCookies();
  const yle_sk = useYleSk();
  const yle_ui_language = useUILanguage();

  const onClick = useCallback(
    (elementAttributes: ObservableLabels) => {
      const eventData: ImpressionEvent = {
        impression_type: 'click',
        impression_timestamp: Date.now(),
        yle_vrs_platform: platform,
        yle_vrs_device: 'browser',
        yle_sk,
        yle_ui_language,
        service_id: 'areena',
        ui_element_position: uiElementPosition,
        ui_element_type: uiElementType,
        ...elementAttributes,
        ...impressionCookiesRef.current,
      };

      trackEvents([eventData]);
    },
    [
      impressionCookiesRef,
      uiElementPosition,
      uiElementType,
      yle_sk,
      yle_ui_language,
      platform,
    ]
  );

  return onClick;
};
