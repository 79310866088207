import { useCookieConsent } from 'contexts/CookieConsentContext';
import { useCookie } from 'hooks/useCookie';
import { CookieLabels } from './types';
import { RefObject, useEffect, useRef } from 'react';

export const useCookies = (): RefObject<CookieLabels | null> => {
  const [yleconsent] = useCookie('yleconsent');
  const [userconsent] = useCookie('userconsent');
  const [yle_rec] = useCookie('yle_rec');
  const [yle_selva] = useCookie('yle_selva');
  const [areena_ab] = useCookie('areena_ab');

  const consent = useCookieConsent();

  const ref = useRef<CookieLabels>({
    userconsent: userconsent || yleconsent || undefined,
    yle_rec: (consent?.development && yle_rec) || undefined,
    yle_selva: (consent?.development && yle_selva) || undefined,
    yle_ab_string: areena_ab || undefined,
  });

  useEffect(() => {
    ref.current = {
      userconsent: userconsent || yleconsent || undefined,
      yle_rec: (consent?.development && yle_rec) || undefined,
      yle_selva: (consent?.development && yle_selva) || undefined,
      yle_ab_string: areena_ab || undefined,
    };
  }, [
    areena_ab,
    consent?.development,
    userconsent,
    yle_rec,
    yle_selva,
    yleconsent,
  ]);

  return ref;
};
