import React, { FunctionComponent } from 'react';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useCards } from 'hooks/useCards';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import { PlaceholderSearchCard } from 'components/PlaceholderSearchCard/PlaceholderSearchCard';
import styles from './MegaMenuSearchList.module.scss';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { ImpressionAnalyticsContextProvider } from 'contexts/ImpressionAnalyticsContext';
import { SearchNotification } from 'components/Notifications/SearchNotification';
import { Control, List, ListStyle, Service } from '@yleisradio/areena-types';
import { useTranslation } from 'hooks/useTranslation';
import { searchRoutes } from 'utils/routes';
import { useUILanguage } from 'hooks/useUILanguage';
import { TextLinkControl } from 'components/TextLinkControl/TextLinkControl';
import { GridContainer, GridElement } from 'components/Grid';
import MegaMenuSearchListCardPage from './MegaMenuSearchListCardPage';
import useSearchNotification from 'components/Notifications/SearchNotification/useSearchNotification';
import { ItemListMicroData } from 'components/List/ItemListMicrodata';

const PAGE_SIZE = 6;

type Lists = {
  [key in Service]: List;
};

type Props = {
  searchQuery: string | null;
  clearSearchQuery(): void;
  lists: Lists;
  service: Service;
  closeMenu(): void;
};

const PlaceholderCards: FunctionComponent<{
  amount: number;
  listStyle: ListStyle | undefined;
}> = ({ amount, listStyle }) => {
  return Array.from({ length: amount }, (_, i) => (
    <GridElement
      key={i}
      mobile={10}
      tablet={10}
      tabletLandscape={6}
      desktopSmall={11}
      desktopXL={12}
      htmlTag="li"
    >
      <div className={styles.searchListItemPlaceholder} key={i}>
        <PlaceholderSearchCard listStyle={listStyle} />
      </div>
    </GridElement>
  ));
};

export const MegaMenuSearchList: FunctionComponent<Props> = ({
  lists,
  searchQuery,
  clearSearchQuery,
  service,
  closeMenu,
}) => {
  const listContentId = sanitizeHtmlId('megamenu-search-results-content');
  const language = useUILanguage();
  const t = useTranslation();

  const visiblePages = 1;

  const tvCards = useCards({
    source: lists['tv'].source,
    pageIndex: visiblePages - 1,
    pageSize: PAGE_SIZE,
  });

  const radioCards = useCards({
    source: lists['radio'].source,
    pageIndex: visiblePages - 1,
    pageSize: PAGE_SIZE,
  });

  const notification = useSearchNotification(service, {
    tv: tvCards.totalCount,
    radio: radioCards.totalCount,
  });

  const searchPageControl: Control = {
    type: 'navigator',
    icon: { id: 'search', version: '1.0.0' },
    title: t('showAllSearchResults'),
    destination: {
      uri: searchQuery
        ? `/${searchRoutes[language]}?q=${searchQuery}&service=${service}`
        : `/${searchRoutes[language]}?service=${service}`,
    },
  };

  const isLoadingFirstCards = tvCards.loading || radioCards.loading;

  const currentServiceHasResults = () => {
    switch (service) {
      case 'tv':
        return tvCards.totalCount > 0;
      case 'radio':
        return radioCards.totalCount > 0;
      default:
        return false;
    }
  };

  const analyticsContext =
    service === 'tv' ? tvCards.analyticsContext : radioCards.analyticsContext;

  const handleRouteToSearchPageClick = () => {
    clearSearchQuery();
    closeMenu();
  };

  return (
    <div className="VerticalList">
      <AnalyticsContextProvider
        context={
          analyticsContext || lists[service].analytics?.context?.comscore
        }
      >
        <ImpressionAnalyticsContextProvider
          uiElementPosition={0}
          uiElementType="list"
        >
          {!currentServiceHasResults() && !isLoadingFirstCards ? (
            <SearchNotification>{notification}</SearchNotification>
          ) : null}

          <section
            className={styles.wrapper}
            aria-labelledby="navigation-search-results-heading"
          >
            <VisuallyHidden asChild>
              <h3 id="navigation-search-results-heading">
                {t('searchResults')}
              </h3>
            </VisuallyHidden>
            <ItemListMicroData id={listContentId} htmlTag="div">
              <GridContainer htmlTag="ul">
                {isLoadingFirstCards ? (
                  <PlaceholderCards
                    amount={PAGE_SIZE}
                    listStyle={lists[service].style}
                  />
                ) : (
                  <MegaMenuSearchListCardPage
                    listStyle={lists[service].style}
                    pageIndex={0}
                    pageKey={listContentId}
                    pageSize={PAGE_SIZE}
                    cards={service === 'tv' ? tvCards.cards : radioCards.cards}
                    onClick={closeMenu}
                  />
                )}
              </GridContainer>
            </ItemListMicroData>
          </section>

          {currentServiceHasResults() && (
            <div className={styles.searchPageLinkContainer}>
              <TextLinkControl
                control={searchPageControl}
                onClick={handleRouteToSearchPageClick}
                service={service}
              />
            </div>
          )}
        </ImpressionAnalyticsContextProvider>
      </AnalyticsContextProvider>
    </div>
  );
};
