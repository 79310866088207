import {
  ObservableLabels,
  Observe,
} from 'contexts/ImpressionAnalyticsContext/types';
import { useClickCapture } from 'contexts/ImpressionAnalyticsContext/useClickCapture';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { useShowObserver } from './useShowObserver';
import { isMobile } from 'react-device-detect';

type ImpressionAnalyticsContextType = {
  observe: Observe;
  captureClick: (labels: ObservableLabels) => void;
};

type Props = {
  children: ReactNode;
  uiElementPosition: number;
  uiElementType: string;
};

const ImpressionAnalyticsContext =
  createContext<ImpressionAnalyticsContextType>({
    observe(): never {
      throw new Error('No providers for ImpressionAnalyticsContext');
    },
    captureClick(): never {
      throw new Error('No providers for ImpressionAnalyticsContext');
    },
  });

export const ImpressionAnalyticsContextProvider: FunctionComponent<Props> = ({
  children,
  uiElementPosition,
  uiElementType,
}) => {
  const platform = isMobile ? 'mobile' : 'desktop';

  const observe = useShowObserver({
    uiElementPosition,
    uiElementType,
    platform,
  });
  const captureClick = useClickCapture({
    uiElementPosition,
    uiElementType,
    platform,
  });

  const contextValue = useMemo<ImpressionAnalyticsContextType>(
    () => ({ observe, captureClick }),
    [captureClick, observe]
  );

  return (
    <ImpressionAnalyticsContext.Provider value={contextValue}>
      {children}
    </ImpressionAnalyticsContext.Provider>
  );
};

export function useImpressionAnalyticsContext(): ImpressionAnalyticsContextType {
  return useContext(ImpressionAnalyticsContext);
}
