import { createIntl, createIntlCache, type IntlShape } from '@formatjs/intl';
import { Language } from '@yleisradio/areena-types';
import { timeZone } from 'utils/time';
import { apmRum } from './apm-rum';
import logger from './logger';
import translationsFi from './translations/fi.json';
import translationsSv from './translations/sv.json';

export type Key = keyof typeof translationsFi & keyof typeof translationsSv;
export type Args = {
  hours: { n: number };
  minutes: { n: number };
  seconds: { n: number };
  queueItemDragStart: { title: string; position: number; count: number };
  queueItemDragOver: { title: string; position: number; count: number };
  queueItemDragEnd: { title: string; position: number; count: number };
};
export type KeyWithArgs = keyof Args;
export type KeyWithoutArgs = Exclude<Key, keyof Args>;

const cache = createIntlCache();
const onError = (error: Error) => {
  logger.error(error);
  apmRum?.captureError(error);
};

export const intlInstances = {
  fi: createIntl(
    {
      locale: 'fi',
      messages: translationsFi,
      onError,
      timeZone,
    },
    cache
  ),
  sv: createIntl(
    {
      locale: 'sv',
      messages: translationsSv,
      onError,
      timeZone,
    },
    cache
  ),
} satisfies Record<Language, IntlShape>;

export function getTranslation(language: Language, key: KeyWithoutArgs): string;
export function getTranslation<K extends KeyWithArgs>(
  language: Language,
  key: K,
  translationArgs: Args[K]
): string;
export function getTranslation(
  language: Language,
  key: Key,
  translationArgs?: Args[KeyWithArgs]
): string {
  return intlInstances[language].formatMessage({ id: key }, translationArgs);
}
