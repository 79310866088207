import React from 'react';
import styles from './ImageLinkControl.module.scss';
import logger from 'services/logger';
import Image from 'next/image';
import { Link } from 'components/Link';
import { defaultLoader, loaderUrl } from 'utils/cloudinary';
import { Control, Service } from '@yleisradio/areena-types';

type Props = {
  control: Control;
  onClick?: (() => void) | undefined;
  service: Service;
  sizes: string;
};

export const ImageLinkControl: React.FC<Props> = ({
  control,
  onClick,
  service,
  sizes,
}) => {
  const { image, title, destination } = control;

  if (!destination) {
    logger.warn('ImageLinkControl is missing destination');
    return null;
  }

  return (
    <Link
      className={styles.link}
      onClick={onClick}
      pointer={destination}
      service={service}
    >
      {image ? (
        <Image
          alt={image.alt || ''}
          className={styles.image}
          fill
          key={image.id}
          loader={defaultLoader}
          priority
          role={image.alt ? undefined : 'presentation'}
          sizes={sizes}
          src={loaderUrl(image)}
        />
      ) : null}
      <span className={styles.title}>{title}</span>
    </Link>
  );
};
