import { Control } from '@yleisradio/areena-types';
import { Action } from './type';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { useState } from 'react';
import { useUILanguage } from 'hooks/useUILanguage';
import { idFromPointer } from 'utils/pointer';
import logger from 'services/logger';
import { getAvailablePlayerByProgramId } from 'services/areena-api/fetchers';
import { toastPlaybackError } from 'components/Notifications';
import { useLocationParameters } from 'hooks/useLocationParameters';

export function useServiceAction(control: Control | null): Action {
  const { closePlayer, setIsPlaying, startNewSession } = usePlayerState();
  const [isExecuting, setIsExecuting] = useState(false);
  const language = useUILanguage();
  const locationParameters = useLocationParameters();

  const serviceId = idFromPointer(control?.destination);

  async function serviceAction() {
    if (isExecuting) return;

    if (!serviceId) {
      logger.error(new Error('Service id needs to be defined'));
      toastPlaybackError(language);
      return;
    }

    if (!locationParameters) {
      logger.error(new Error('Location parameters need to be defined'));
      toastPlaybackError(language);
      return;
    }

    setIsExecuting(true);

    try {
      const player = await getAvailablePlayerByProgramId(
        serviceId,
        language,
        locationParameters
      );

      if (player) {
        startNewSession(player);
        setIsPlaying(true);
      } else {
        logger.debug('No available player, closing player');
        closePlayer();
        toastPlaybackError(language);
      }
    } catch (e) {
      logger.error(e);
      closePlayer();
      toastPlaybackError(language);
    }

    setIsExecuting(false);
  }

  return {
    runAction: serviceAction,
    executing: isExecuting,
  };
}
