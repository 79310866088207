import React, { FC, PropsWithChildren, SVGProps } from 'react';
import { Link } from 'components/Link';
import styles from 'components/Navigation/NavigationLink.module.scss';
import classNames from 'classnames';

type Props = {
  icon: FC<SVGProps<SVGAElement>>;
  activeIcon: FC<SVGProps<SVGAElement>>;
  isActive: boolean;
  to: string;
};

const NavigationLink: FC<PropsWithChildren<Props>> = ({
  icon: Icon,
  activeIcon: ActiveIcon,
  isActive,
  to,
  children,
}) => {
  return (
    <Link
      pointer={{ uri: to }}
      aria-current={isActive}
      className={classNames(styles.link, isActive && styles.activeLink)}
    >
      {isActive ? (
        <ActiveIcon className={styles.icon} />
      ) : (
        <Icon className={styles.icon} />
      )}

      <span className={styles.linkText}>{children}</span>
    </Link>
  );
};

export { NavigationLink };
