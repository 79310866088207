import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useRef,
} from 'react';
import { useTunnusContext } from 'contexts/TunnusContext';
import { apmRum } from 'services/apm-rum';
import {
  initSnowplowTracker,
  addConsentContext,
  setUser,
  clearUser,
} from 'services/snowplowAnalytics/snowplowAnalytics';
import logger from 'services/logger';
import { BrowserTracker } from '@snowplow/browser-tracker';
import { useCookieConsent } from 'contexts/CookieConsentContext';
import { useUILanguage } from 'hooks/useUILanguage';
import { isTestEnvironment } from 'utils/environment';

declare global {
  interface Window {
    yleSnowplow?: BrowserTracker | null | undefined;
    yleSnowplowReady?: Promise<boolean> | undefined;
  }
}

type SnowplowContextType = BrowserTracker | null | undefined;

const SnowplowContext = createContext<SnowplowContextType>(null);

type SnowplowProviderProps = {
  children: ReactNode;
};

export const SnowplowProvider: React.FC<SnowplowProviderProps> = ({
  children,
}) => {
  const language = useUILanguage();
  const consent = useCookieConsent();
  const { userInformation: { userId } = {}, isAuthenticated } =
    useTunnusContext();

  const [snowplowInstance, setSnowPlowInstance] = useState<
    BrowserTracker | null | undefined
  >(null);

  const resolveRef = useRef<(value: boolean) => void | null>(null); // Ref to store resolve function
  const rejectRef = useRef<(value: boolean) => void | null>(null); // Ref to store reject function

  const [snowplowReadyPromise, setSnowplowReadyPromise] =
    useState<Promise<boolean> | null>(null);

  const isFullTrackingAllowed = !!consent;

  useEffect(() => {
    if (!isTestEnvironment()) return; // We only run snowplow in test environment for now

    if (snowplowReadyPromise === null) {
      const pendingPromise = new Promise<boolean>((resolve, reject) => {
        resolveRef.current = resolve;
        rejectRef.current = reject;
      });

      setSnowplowReadyPromise(pendingPromise);
      window.yleSnowplowReady = pendingPromise; // expose the promise to window object
    }

    // When consent is true and tracker is initialized, resolve the promise
    if (resolveRef.current && consent && snowplowInstance) {
      resolveRef.current(true);
    }
  }, [snowplowInstance, consent, snowplowReadyPromise]);

  // init snowplow with anonymous tracking
  useEffect(() => {
    try {
      const tracker = initSnowplowTracker(language);
      window.yleSnowplow = tracker;
      setSnowPlowInstance(tracker);
    } catch (error) {
      logger.error(error, 'Failed to initialize Snowplow tracker');
      if (error instanceof Error) apmRum?.captureError(error);
      if (rejectRef.current) {
        rejectRef.current(false);
      }
    }
  }, [language]);

  // update consent context
  useEffect(() => {
    if (consent) {
      addConsentContext(consent);
    }
  }, [consent]);

  // set user id / clear user
  useEffect(() => {
    if (isFullTrackingAllowed && isAuthenticated && userId) {
      setUser(userId);
    } else {
      clearUser();
    }
  }, [isFullTrackingAllowed, isAuthenticated, userId]);

  return (
    <SnowplowContext.Provider value={snowplowInstance}>
      {children}
    </SnowplowContext.Provider>
  );
};

export function useSnowplow(): BrowserTracker | null | undefined {
  return useContext(SnowplowContext);
}
