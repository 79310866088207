import { useEffect, useState } from 'react';

// Keep in sync with styles/breakpoints.scss
const breakpoints = {
  'mobile-landscape': '480px',
  tablet: '640px',
  'tablet-landscape': '800px',
  'desktop-small': '1020px',
  desktop: '1400px',
  'desktop-xl': '1920px',
} as const satisfies { [key: string]: string };

type Breakpoint = keyof typeof breakpoints;

export function useBreakpoint(breakpoint: Breakpoint): boolean {
  const query = `(min-width: ${breakpoints[breakpoint]})`;

  const [matches, setMatches] = useState(() =>
    typeof window !== 'undefined' ? window.matchMedia(query).matches : false
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    const handleChange = () => setMatches(mediaQueryList.matches);

    mediaQueryList.addEventListener('change', handleChange);

    setMatches(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
}
