import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { BrowseMenuContent } from '@yleisradio/areena-types/domain/v2/browseMenuContent';
import HeadphonesIconActive from 'assets/headphones-active.svg';
import HeadphonesIcon from 'assets/headphones.svg';
import LiveStreamingIconActive from 'assets/live-streaming-active.svg';
import LiveStreamingIcon from 'assets/live-streaming.svg';
import SearchIconActive from 'assets/search-active.svg';
import SearchIcon from 'assets/search.svg';
import TvIconActive from 'assets/tv-active.svg';
import TvIcon from 'assets/tv.svg';
import UserIcon from 'assets/user.svg';
import classNames from 'classnames';
import { useLoginAction } from 'components/Controls/Control/actions/useLoginAction';
import { VerticalDivider } from 'components/Divider/VerticalDivider';
import { MegaMenu } from 'components/Navigation/MegaMenu/MegaMenu';
import { NavigationLink } from 'components/Navigation/NavigationLink';
import { navigationServiceFromClientPath } from 'components/Navigation/navigationService';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { LocationContextProvider } from 'contexts/LocationContext';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useCustomEventAnalytics } from 'hooks/analytics';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { serviceRoutes } from 'utils/routes';
import { AreenaLink } from './AreenaLink';
import styles from './Navigation.module.scss';
import { UserDropdown } from './UserDropdown';

const SearchButton: FC<{
  isActive: boolean;
  isTextVisible: boolean;
}> = ({ isActive, isTextVisible }) => {
  const trackEvent = useCustomEventAnalytics();
  const t = useTranslation();

  return (
    <Dialog.Trigger
      className={classNames(
        styles.searchButton,
        isActive && styles.searchButtonActive
      )}
      onClick={() => {
        trackEvent('header-searchfield-click');
      }}
    >
      {isActive ? (
        <SearchIconActive className={styles.icon} />
      ) : (
        <SearchIcon className={styles.icon} />
      )}
      {isTextVisible ? (
        <span>{t('searchAndBrowse')}</span>
      ) : (
        <VisuallyHidden>{t('searchAndBrowse')}</VisuallyHidden>
      )}
    </Dialog.Trigger>
  );
};

type UserButtonProps = {
  isTextVisible: boolean;
  userDropdown: React.ReactElement;
};

const UserButton: FC<UserButtonProps> = ({ isTextVisible, userDropdown }) => {
  const trackEvent = useCustomEventAnalytics();
  const t = useTranslation();
  const { isAuthenticated } = useTunnusContext();
  const loginAction = useLoginAction();

  return isAuthenticated ? (
    userDropdown
  ) : (
    <button
      className={styles.loginButton}
      onClick={() => {
        trackEvent('header-tunnus-login-click');
        loginAction?.runAction();
      }}
      data-testid="header-login"
    >
      {isTextVisible ? (
        <span>{t('logIn')}</span>
      ) : (
        <VisuallyHidden>{t('logIn')}</VisuallyHidden>
      )}
      <UserIcon className={styles.icon} />
    </button>
  );
};

type Props = {
  browseMenuContent: BrowseMenuContent | null;
};

export const Navigation: FC<Props> = ({ browseMenuContent }) => {
  const t = useTranslation();
  const language = useUILanguage();
  const { asPath } = useRouter();
  const { areenaService } = useAreenaService();

  const activeNavigationService =
    navigationServiceFromClientPath(asPath) ?? areenaService;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      closeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  return (
    <Dialog.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <nav
        aria-label={t('mainMenu')}
        className={styles.root}
        data-testid="app-navigation"
      >
        <LocationContextProvider>
          <div className={styles.mobile} data-testid="app-navigation-mobile">
            <div className={styles.headerTopBar}>
              <UserButton
                isTextVisible={false}
                userDropdown={<UserDropdown breakpoint="mobile" />}
              />
              <AreenaLink
                activeNavigationService={activeNavigationService}
                onClick={closeMenu}
              />
              <SearchButton
                isActive={activeNavigationService === 'search'}
                isTextVisible={false}
              />
            </div>

            <ul className={styles.headerBottomBar}>
              <li>
                <NavigationLink
                  icon={TvIcon}
                  activeIcon={TvIconActive}
                  isActive={activeNavigationService === 'tv'}
                  to={`/${serviceRoutes['tv'][language]}`}
                >
                  {t('tv')}
                </NavigationLink>
              </li>
              <li>
                <NavigationLink
                  icon={HeadphonesIcon}
                  activeIcon={HeadphonesIconActive}
                  isActive={activeNavigationService === 'radio'}
                  to={`/${serviceRoutes['radio'][language]}`}
                >
                  {t('podcasts')}
                </NavigationLink>
              </li>
              <li>
                <NavigationLink
                  icon={LiveStreamingIcon}
                  activeIcon={LiveStreamingIconActive}
                  isActive={activeNavigationService === 'broadcasts'}
                  to={`/${serviceRoutes['broadcasts'][language]}`}
                >
                  {t('broadcasts')}
                </NavigationLink>
              </li>
            </ul>
          </div>
          <div className={styles.desktop} data-testid="app-navigation-desktop">
            <AreenaLink
              activeNavigationService={activeNavigationService}
              onClick={closeMenu}
            />
            <div className={styles.desktopMiddleContainer}>
              <ul className={styles.list}>
                <li>
                  <NavigationLink
                    icon={TvIcon}
                    activeIcon={TvIconActive}
                    isActive={activeNavigationService === 'tv'}
                    to={`/${serviceRoutes['tv'][language]}`}
                  >
                    {t('tv')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink
                    icon={HeadphonesIcon}
                    activeIcon={HeadphonesIconActive}
                    isActive={activeNavigationService === 'radio'}
                    to={`/${serviceRoutes['radio'][language]}`}
                  >
                    {t('podcasts')}
                  </NavigationLink>
                </li>
                <li>
                  <NavigationLink
                    icon={LiveStreamingIcon}
                    activeIcon={LiveStreamingIconActive}
                    isActive={activeNavigationService === 'broadcasts'}
                    to={`/${serviceRoutes['broadcasts'][language]}`}
                  >
                    {t('broadcasts')}
                  </NavigationLink>
                </li>
              </ul>
              <VerticalDivider />
              <SearchButton
                isActive={activeNavigationService === 'search'}
                isTextVisible={true}
              />
            </div>
            <div className={styles.desktopRightContainer}>
              <VerticalDivider />
              <UserButton
                isTextVisible={true}
                userDropdown={<UserDropdown breakpoint="desktop" />}
              />
            </div>
          </div>

          <Dialog.Portal>
            <Dialog.Overlay className={styles.contentOverlay}>
              <MegaMenu
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                closeMenu={closeMenu}
                browseMenuContent={browseMenuContent}
              />
            </Dialog.Overlay>
          </Dialog.Portal>
        </LocationContextProvider>
      </nav>
    </Dialog.Root>
  );
};
