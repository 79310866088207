import React, { forwardRef } from 'react';

type Props = {
  className?: string | undefined;
  id?: string;
  children: React.ReactNode;
  htmlTag?: React.HTMLElementType;
};

export const ItemListMicroData = forwardRef(function ItemListMicroData(
  { className, id, children, htmlTag = 'ul' }: Props,
  ref: React.ForwardedRef<HTMLElement>
) {
  const Tag = htmlTag as React.ElementType;

  return (
    <Tag
      className={className}
      id={id}
      itemScope
      itemType="https://schema.org/ItemList"
      ref={ref}
    >
      {children}
    </Tag>
  );
});
