import { useCallback, useState } from 'react';
import { useTunnusContext } from 'contexts/TunnusContext';
import logger from 'services/logger';
import { Action } from './type';

export function useBeginIdentificationAction(): Action {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { yleTunnusInstance } = useTunnusContext();

  const runAction = useCallback(async () => {
    if (yleTunnusInstance) {
      setIsDialogOpen(true);

      try {
        await yleTunnusInstance.beginIdentification(window.location.href);
      } catch (e) {
        logger.error(e, 'Error when initiating identification');
      }

      setIsDialogOpen(false);
    } else {
      logger.warn(
        new Error(
          "Couldn't initiate identification as yleTunnusInstance is undefined"
        )
      );
    }
  }, [setIsDialogOpen, yleTunnusInstance]);

  return {
    runAction,
    executing: isDialogOpen,
  };
}
