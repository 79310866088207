import React, { FC } from 'react';
import { Link } from 'components/Link';
import { Control, Service } from '@yleisradio/areena-types';
import { useIcon } from 'components/Controls/Control/useIcon';
import styles from './TextLinkControl.module.scss';
import { useControl } from 'components/Controls/Control/useControl';
import {
  isActivator,
  isNavigator,
} from 'components/Controls/Control/typeGuards';
import { getOtherLanguageURL } from 'utils/url';
import { useUILanguage } from 'hooks/useUILanguage';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { FunctionalityId } from 'utils/control';
import { useRouter } from 'next/router';

type Props = {
  control: Control;
  onClick(): void;
  service: Service;
};

const TextLinkControl: FC<Props> = ({
  control: controlProp,
  onClick,
  service,
}) => {
  const Icon = useIcon(controlProp);
  const { control } = useControl({
    control: controlProp,
  });

  const language = useUILanguage();
  const { areenaService } = useAreenaService();
  const { asPath: path, query } = useRouter();

  const otherLanguageURL = getOtherLanguageURL(
    path,
    query,
    language,
    areenaService
  );

  return (
    <>
      {isNavigator(control) && control.destination && (
        <Link
          pointer={control.destination}
          className={styles.link}
          onClick={onClick}
          service={service}
        >
          {Icon && <Icon className={styles.icon} />}
          <span className={styles.text}>{control.title}</span>
        </Link>
      )}
      {isActivator(control) &&
        control.functionality.id === FunctionalityId.SwitchLanguage && (
          <Link
            className={styles.link}
            lang={language === 'fi' ? 'sv' : 'fi'}
            pointer={{
              uri: otherLanguageURL,
            }}
          >
            {Icon && <Icon className={styles.icon} />}
            <span className={styles.text}>{control.title}</span>
          </Link>
        )}
    </>
  );
};

export { TextLinkControl };
