import {
  Card,
  HistoryResponse,
  Label,
  LabelType,
} from '@yleisradio/areena-types';
import { idFromPointer } from './pointer';

export function findLabel(
  labels: Label[] | undefined,
  type: LabelType
): Label | undefined {
  return labels && labels.find((label) => label.type === type);
}

export function filterLabelsByType(
  type: LabelType,
  labels: Label[] | undefined
): Label[] {
  return labels ? labels.filter((label) => label.type === type) : [];
}

export function getFormattedLabels(labels: Label[] | undefined): Label[] {
  return labels ? labels.filter((label) => !!label.formatted) : [];
}

export function getCardKey(card: Card, listKey: string): string {
  return `${listKey}-${card.title}-${card.pointer?.uri}-${card.description}`;
}

export function getCardProgressIds(cards: Card[]): string[] {
  return cards.reduce<string[]>((acc, card) => {
    const progressLabel = findLabel(card.labels, 'progress');
    if (progressLabel && progressLabel.pointer) {
      const id = idFromPointer(progressLabel.pointer);
      if (id) return [...acc, id];
    }
    return acc;
  }, []);
}

export function getHistoryElapsedForCard(
  card: Card,
  historyEntries: HistoryResponse | null
): string | null {
  const progressLabel = findLabel(card.labels, 'progress');
  const programId = idFromPointer(progressLabel?.pointer);

  if (!historyEntries || !programId) return null;

  return historyEntries[programId]?.elapsed || null;
}

export function getLabelKey(label: Label, cardKey: string): string {
  return `${cardKey}-${label.type}-${label.raw}-${label.formatted}`;
}

/**
 * Label['formatted'] has "klo" prefix. Use this util when only the date is needed.
 * @param labels
 * @returns {{broadcastEndDate: Date | undefined, broadcastStartDate: Date | undefined}}
 */
export const getBroadcastDates = (
  labels: Label[] | undefined
): {
  broadcastStartDate: Date | undefined;
  broadcastEndDate: Date | undefined;
} => {
  const broadcastStartDateLabel = findLabel(labels, 'broadcastStartDate');
  const broadcastStartDate =
    typeof broadcastStartDateLabel?.raw === 'string'
      ? new Date(broadcastStartDateLabel.raw)
      : undefined;

  const broadcastEndDateLabel = findLabel(labels, 'broadcastEndDate');

  const broadcastEndDate =
    typeof broadcastEndDateLabel?.raw === 'string'
      ? new Date(broadcastEndDateLabel.raw)
      : undefined;

  return { broadcastStartDate, broadcastEndDate };
};
