import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import YleAreenaLogo from 'assets/areena.svg';
import YleArenanLogo from 'assets/arenan.svg';
import { Link } from 'components/Link';
import { NavigationService } from 'components/Navigation/navigationService';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import { FC } from 'react';
import { serviceRoutes } from 'utils/routes';
import styles from './AreenaLink.module.scss';

export const AreenaLink: FC<{
  activeNavigationService: NavigationService | undefined;
  onClick(): void;
}> = ({ activeNavigationService, onClick }) => {
  const t = useTranslation();
  const language = useUILanguage();
  const to =
    activeNavigationService === 'radio'
      ? serviceRoutes['radio'][language]
      : serviceRoutes['tv'][language];

  return (
    <Link pointer={{ uri: `/${to}` }} className={styles.root} onClick={onClick}>
      {language === 'fi' ? (
        <YleAreenaLogo className={styles.logo} />
      ) : (
        <YleArenanLogo className={styles.logo} />
      )}
      <VisuallyHidden>{t('areena')}</VisuallyHidden>
    </Link>
  );
};
