import type { TranslationFunction } from 'hooks/useTranslation';

export const timeZone = 'Europe/Helsinki';

const getHoursString = (date: Date) => {
  const hours = date.getUTCHours();
  return hours > 0 ? `${hours.toString()}:` : '';
};

const getMinutesString = (date: Date) =>
  `${date.getUTCMinutes().toString().padStart(2, '0')}:`;

const getSecondsString = (date: Date) =>
  date.getUTCSeconds().toString().padStart(2, '0');

export const progressToString = (progressInSeconds: number) => {
  const date = new Date(progressInSeconds * 1000);
  const hours = getHoursString(date);
  const minutes = getMinutesString(date);
  const seconds = getSecondsString(date);
  return `${hours}${minutes}${seconds}`;
};

const joinWithAnd = (timeParts: Array<string | null>, and: string): string => {
  return timeParts.filter((part) => part !== null).join(` ${and} `);
};

export const progressToText = (
  progressInSeconds = 0,
  translate: TranslationFunction
): string => {
  const date = new Date(progressInSeconds * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const hoursText = hours > 0 ? translate('hours', { n: hours }) : null;
  const minutesText = minutes > 0 ? translate('minutes', { n: minutes }) : null;
  const secondsText = seconds > 0 ? translate('seconds', { n: seconds }) : null;

  return joinWithAnd([hoursText, minutesText, secondsText], translate('and'));
};
