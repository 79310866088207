import { useCallback } from 'react';
import {
  Args,
  getTranslation,
  Key,
  KeyWithArgs,
  KeyWithoutArgs,
} from 'services/translations';
import { useUILanguage } from './useUILanguage';

export type TranslationFunction = {
  (key: KeyWithoutArgs, translationArgs?: undefined): string;
  <K extends KeyWithArgs>(key: K, translationArgs: Args[K]): string;
};

export function useTranslation(): TranslationFunction {
  const language = useUILanguage();

  return useCallback<TranslationFunction>(
    (key: Key, translationArgs?: Args[keyof Args]) =>
      translationArgs
        ? getTranslation(language, key as KeyWithArgs, translationArgs)
        : getTranslation(language, key as KeyWithoutArgs),
    [language]
  );
}
