import styles from './Grid.module.scss';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  gap?: 'default' | 'small';
  htmlTag?: React.HTMLElementType;
}

export const GridContainer: React.FC<Props> = ({
  children,
  htmlTag,
  gap = 'default',
}) => {
  const Tag = htmlTag || 'div';
  return (
    <Tag
      className={classNames(
        styles.gridContainer,
        gap === 'small' && styles.smallGap
      )}
    >
      {children}
    </Tag>
  );
};
