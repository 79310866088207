import { useEffect, useRef, useState } from 'react';
import logger from 'services/logger';
import { YleTunnusInstance } from 'types/yleTunnus';
import { calculateIdTokenRefreshWaitTime } from 'utils/login';

type IdTokenRefresherProps = {
  isAuthenticated: boolean | null;
  yleTunnusInstance: YleTunnusInstance | undefined;
};

type TimeoutId = ReturnType<typeof setTimeout>;

// This component handles the ID token refresh logic in isolation to prevent
// changes to the ID token from causing re-renders of any child components
// of TunnusContext.
export const IdTokenRefresher: React.FC<IdTokenRefresherProps> = ({
  isAuthenticated,
  yleTunnusInstance,
}) => {
  const [idToken, setIdToken] = useState<string | null>(null);
  const idTokenRefreshTimeout = useRef<TimeoutId | null>(null);

  useEffect(() => {
    if (!isAuthenticated || !yleTunnusInstance || idTokenRefreshTimeout.current)
      return;

    let waitTime = 0;

    if (idToken) {
      const { iat, exp } = yleTunnusInstance.getIdTokenTimestamps(idToken);
      const now = Date.now();
      waitTime = calculateIdTokenRefreshWaitTime(iat * 1000, exp * 1000, now);

      /*
        To prevent infinite loops, do not set up a refresh if the newly refreshed
        ID token is already past the refresh time.
      */
      if (waitTime === 0) return;
    }

    idTokenRefreshTimeout.current = setTimeout(async () => {
      try {
        const { token } = await yleTunnusInstance.getIdToken();
        setIdToken(token);
        idTokenRefreshTimeout.current = null;
      } catch (e) {
        logger.error(e, 'Error during ID token refresh');
        /*
          Note: idTokenRefreshTimeout.current is not cleared in case of an error
          to prevent infinite loops
        */
      }
    }, waitTime);

    return () => {
      if (idTokenRefreshTimeout.current) {
        clearTimeout(idTokenRefreshTimeout.current);
        idTokenRefreshTimeout.current = null;
      }
    };
  }, [idToken, isAuthenticated, yleTunnusInstance]);

  return null;
};
