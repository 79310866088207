import { useAnalyticsAvailability } from 'contexts/AnalyticsAvailabilityContext';
import { useUILanguage } from 'hooks/useUILanguage';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import logger from 'services/logger';
import { yleTunnusSdkUrl } from 'services/properties/frontend';
import { initializeTunnus } from 'services/yleTunnusSdk';
import { UserInfo, YleTunnusInstance } from 'types/yleTunnus';
import { IdTokenRefresher } from './IdTokenRefresher';

/** User information is considered fetched after this amount of milliseconds */
const userInformationFetchWaitTime = 8000;

type Value = {
  isAuthenticated: boolean | null;
  isUserInformationFetched: boolean;
  userInformation: UserInfo | undefined;
  yleTunnusInstance: YleTunnusInstance | undefined;
};

const TunnusContext = createContext<Value>({
  isAuthenticated: null,
  isUserInformationFetched: false,
  userInformation: undefined,
  yleTunnusInstance: undefined,
});

interface TunnusContextProps {
  children: React.ReactNode;
  isAuthenticated: boolean | null;
}

export const TunnusContextProvider: React.FunctionComponent<
  TunnusContextProps
> = ({ children, isAuthenticated }) => {
  const [yleTunnusInstance, setYleTunnusInstance] =
    useState<YleTunnusInstance>();
  const [isUserInformationFetched, setIsUserInformationFetched] =
    useState<boolean>(false);
  const [userInformation, setUserInformation] = useState<UserInfo>();

  const language = useUILanguage();
  const { isAvailable: isAnalyticsAvailable, hasErrored: hasAnalyticsErrored } =
    useAnalyticsAvailability();
  const router = useRouter();

  useEffect(() => {
    if (yleTunnusInstance) {
      const subscriber = yleTunnusInstance.state.subscribe((state) => {
        setUserInformation(state.user || undefined);
        setIsUserInformationFetched(true);
      });
      return () => {
        subscriber.unsubscribe();
      };
    }
  }, [setIsUserInformationFetched, setUserInformation, yleTunnusInstance]);

  useEffect(() => {
    const id = setTimeout(() => {
      setIsUserInformationFetched(true);
    }, userInformationFetchWaitTime);

    return () => {
      clearTimeout(id);
    };
  }, [setIsUserInformationFetched]);

  useEffect(() => {
    if (yleTunnusInstance) {
      const subscriber = yleTunnusInstance.state.subscribe((state) => {
        if (isAuthenticated !== null && state.loggedIn !== isAuthenticated) {
          // Do client-side refresh
          router.replace(router.asPath, undefined, { scroll: false });
        }
      });
      return () => {
        subscriber.unsubscribe();
      };
    }
  }, [isAuthenticated, router, yleTunnusInstance]);

  const value = useMemo<Value>(
    () => ({
      isAuthenticated: isAuthenticated,
      isUserInformationFetched,
      userInformation,
      yleTunnusInstance,
      setYleTunnusInstance,
    }),
    [
      isAuthenticated,
      isUserInformationFetched,
      userInformation,
      yleTunnusInstance,
      setYleTunnusInstance,
    ]
  );

  return (
    <TunnusContext.Provider value={value}>
      {(isAnalyticsAvailable || hasAnalyticsErrored) && (
        <Script
          src={yleTunnusSdkUrl}
          onLoad={() => {
            initializeTunnus(language)
              .then((yleTunnusInstance) => {
                setYleTunnusInstance(yleTunnusInstance);
              })
              .catch((e) => {
                logger.error(e, 'Error during YleTunnus initialization');
              });
          }}
        />
      )}
      <IdTokenRefresher
        isAuthenticated={isAuthenticated}
        yleTunnusInstance={yleTunnusInstance}
      />

      {children}
    </TunnusContext.Provider>
  );
};

export function useTunnusContext(): Value {
  return useContext(TunnusContext);
}
