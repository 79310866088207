import { Language } from '@yleisradio/areena-types';
import { BrazeSdkType } from 'contexts/BrazeSdkContext/types';
import getConfig from 'next/config';
import { brazeSdkProperties } from './properties/frontend';
import logger from './logger';

export async function init(
  braze: BrazeSdkType,
  userId: string,
  token: string,
  refreshToken: () => void,
  language: Language
): Promise<void> {
  const { apiKey, endpoint } = brazeSdkProperties;
  const {
    publicRuntimeConfig: { versionNumber },
  } = getConfig();

  if (!apiKey) {
    throw new Error('No Braze SDK API key provided');
  }

  const success = braze.initialize(apiKey, {
    baseUrl: endpoint,
    doNotLoadFontAwesome: true,
    enableSdkAuthentication: true,
    localization: language,
    ...(versionNumber && { appVersion: versionNumber }),
  });

  if (success === false) {
    throw new Error('Failed to initialize Braze SDK');
  }

  braze.subscribeToSdkAuthenticationFailures(({ errorCode, reason }) => {
    logger.debug('Braze SDK authentication failed', { errorCode, reason });
    refreshToken();
  });

  braze.subscribeToInAppMessage((message) => {
    if (message.isControl || message instanceof braze.SlideUpMessage) {
      braze.showInAppMessage(message);
    } else {
      logger.debug('In-app message type not supported', { message });
    }
  });

  braze.changeUser(userId, token);

  braze.getUser()?.setLanguage(language);

  braze.openSession();
}

export function teardown(braze: BrazeSdkType): void {
  braze.wipeData();
  braze.destroy();
  window.location.reload();
}
