import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Language, Service } from '@yleisradio/areena-types';
import FavoriteIcon from 'assets/favoriteOffIcon.svg';
import LogOutIcon from 'assets/log-out.svg';
import UserIcon from 'assets/user.svg';
import BarChartIcon from 'assets/barChart.svg';
import ChevronRightIcon from 'assets/chevronRight.svg';
import MailIcon from 'assets/mail.svg';
import { useLogOutAction } from 'components/Controls/Control/actions/useLogOutAction';
import { Link } from 'components/Link';
import { UserInitials } from 'components/UserInitials/UserInitials';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import { FC } from 'react';
import {
  personalPodcastRoutes,
  personalRoutes,
  personalTVRoutes,
} from 'utils/routes';
import { Profile } from './Profile';
import styles from './UserDropdown.module.scss';

function getPersonalUri(
  areenaService: Service | undefined,
  language: Language
): string {
  if (!areenaService) return `/${personalRoutes[language]}`;

  const personalUrl =
    areenaService === 'tv'
      ? personalTVRoutes[language]
      : personalPodcastRoutes[language];

  return `/${personalUrl}`;
}

type Props = {
  breakpoint: 'mobile' | 'desktop';
};

export const UserDropdown: FC<Props> = ({ breakpoint }) => {
  const t = useTranslation();
  const language = useUILanguage();
  const logOutAction = useLogOutAction();
  const { areenaService } = useAreenaService();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        aria-label={t('profileMenu')}
        className={styles.trigger}
        data-testid="user-dropdown-trigger"
      >
        <UserInitials size="sm" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        align={breakpoint === 'mobile' ? 'start' : 'end'}
        className={styles.content}
        side="bottom"
        sideOffset={8}
        collisionPadding={8}
      >
        <DropdownMenu.Label>
          <Profile />
        </DropdownMenu.Label>

        <DropdownMenu.Group className={styles.group}>
          <DropdownMenu.Item asChild>
            <Link
              className={styles.item}
              pointer={{ uri: getPersonalUri(areenaService, language) }}
            >
              <FavoriteIcon className={styles.icon} aria-hidden />
              {t('ownPage')}
              <ChevronRightIcon className={styles.icon} aria-hidden />
            </Link>
          </DropdownMenu.Item>
        </DropdownMenu.Group>

        <DropdownMenu.Group className={styles.group}>
          <DropdownMenu.Item asChild>
            <Link
              className={styles.item}
              pointer={{
                uri:
                  language === 'fi'
                    ? 'https://tunnus.yle.fi/omat-tiedot'
                    : 'https://tunnus.yle.fi/mina-uppgifter',
              }}
            >
              <UserIcon className={styles.icon} aria-hidden />
              {t('personalInfo')}
              <ChevronRightIcon className={styles.icon} aria-hidden />
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <Link
              className={styles.item}
              pointer={{
                uri:
                  language === 'fi'
                    ? 'https://tunnus.yle.fi/uutiskirjeet'
                    : 'https://tunnus.yle.fi/nyhetsbrev',
              }}
            >
              <MailIcon className={styles.icon} aria-hidden />
              {t('messageSettings')}
              <ChevronRightIcon className={styles.icon} aria-hidden />
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <Link
              className={styles.item}
              pointer={{
                uri:
                  language === 'fi'
                    ? 'https://tunnus.yle.fi/historia'
                    : 'https://tunnus.yle.fi/historik',
              }}
            >
              <BarChartIcon className={styles.icon} aria-hidden />
              {t('mediaConsumption')}
              <ChevronRightIcon className={styles.icon} aria-hidden />
            </Link>
          </DropdownMenu.Item>
        </DropdownMenu.Group>

        <DropdownMenu.Group className={styles.group}>
          <DropdownMenu.Item
            className={styles.item}
            data-testid="logout"
            onClick={() => {
              logOutAction?.runAction();
            }}
          >
            {t('logOut')}
            <LogOutIcon className={styles.icon} aria-hidden />
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
