import { Language } from '@yleisradio/areena-types';
import { Consent } from 'types/yleConsentSdk';

/* yle_context is documented here:
 https://docs.google.com/spreadsheets/d/1hPJ7wu9y6AcDsP6GcZdgxIvxElzyL9Eh0AbRZQ3u2fA/edit?gid=0#gid=0
 */

export const yleContextGenerator = (language: Language) => ({
  schema: 'iglu:fi.yle/yle_context/jsonschema/1-0-3',
  data: {
    main_service: 'yle-areena',
    service: 'areena-web',
    section: 'page', // this should  be updated according to current page?
    service_language: language,
    content_language: language,
    wrapper_type: 'web', // ["web”, "mob”, "webview”, "iframe”, "chromecast_receiver", …]
    context_index: 1, // wrapper context index. > 1 means measuring happened inside iframe / webview etc.
  },
});

export const yleConsentGenerator = (consent: Consent) => ({
  schema: 'iglu:fi.yle/consent/jsonschema/2-0-0',
  data: {
    version: `v${consent.version}`,
    development: consent.development,
    personalization: consent.personalization,
    embedded_social_media: consent.embedded_social_media,
    targeting: consent.targeting,
  },
});
