import { isTestEnvironment } from './environment';

/**
 * @param issuedTime time when the token was issued in milliseconds
 * @param expiresTime time when the token expires in milliseconds
 * @param currentTime current time in milliseconds
 * @returns time in milliseconds to wait before refreshing the token
 */
export function calculateIdTokenRefreshWaitTime(
  issuedTime: number,
  expiresTime: number,
  currentTime: number
): number {
  const validityPeriod = expiresTime - issuedTime;
  const halfValidityPeriod = validityPeriod / 2;
  const refreshTime = issuedTime + halfValidityPeriod;
  const waitTime = Math.max(0, refreshTime - currentTime);

  return waitTime;
}

export const isAuthenticated = (
  cookies: Record<string, string | undefined>
): boolean => (isTestEnvironment() ? 'yletestlogin' : 'ylelogin') in cookies;
